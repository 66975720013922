<script>
// import Multiselect from "@vueform/multiselect";
// import "@vueform/multiselect/themes/default.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config";
import Swal from "sweetalert2";
import Axios from "@/config/axios";
import Lottie from "@/components/widgets/lottie.vue";
import animationData from "@/components/widgets/msoeawqm.json";
import animationData1 from "@/components/widgets/gsqxdxog.json";
import store from "../../../state/store";

export default {
  page: {
    title: "Clients des colis",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Clients des colis",
      items: [
        {
          text: "Tracking de colis",
          href: "/",
        },
        {
          text: "Clients",
          active: true,
        },
      ],
      date1: null,
      date: null,
      rangeDateconfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "M j, Y",
        altInput: true,
        dateFormat: "d M, Y",
        mode: "range",
      },
      config: {
        enableTime: false,
        dateFormat: "d M, Y",
      },
      searchQuery: null,
      defaultOptions: {
        animationData: animationData,
      },
      defaultOptions2: {
        animationData: animationData1,
      },
      page: 1,
      perPage: 8,
      value: "All",
      pages: [],
      customerList: [],
      fields: {
        client_name: "",
        phone: "",
        email: "",
        location: "",
        birth_date: null,
        gender: "M",
      },
    };
  },
  computed: {
    displayedPosts() {
      return this.paginate(this.customerList);
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.displayedPosts.filter((data) => {
          return (
            data.email.toLowerCase().includes(search) ||
            (data.customer && data.customer.toLowerCase().includes(search)) ||
            (data.phone && data.phone.includes(search)) ||
            data.date.toLowerCase().includes(search) ||
            data.role.toLowerCase().includes(search)
          );
        });
      } else if (this.date !== null) {
        if (this.date !== null) {
          var date1 = this.date.split(" to ")[0];
          var date2 = this.date.split(" to ")[1];
        }
        return this.displayedPosts.filter((data) => {
          if (
            new Date(data.date.slice(0, 12)) >= new Date(date1) &&
            new Date(data.date.slice(0, 12)) <= new Date(date2)
          ) {
            return data;
          } else {
            return null;
          }
        });
      } else if (this.value !== null) {
        return this.displayedPosts.filter((data) => {
          if (data.role === this.value || this.value == "All") {
            return data;
          } else {
            return null;
          }
        });
      } else {
        return this.displayedPosts;
      }
    },
  },

  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  async beforeRouteEnter() {
    await Axios.get("/admin/tracking-customers")
      .then((data) => {
        const customerList = [];
        data.data.data.forEach((row) => {
          const monthNames = [
            "Jan",
            "Fev",
            "Mar",
            "Avr",
            "Mai",
            "Juin",
            "Juil",
            "Aou",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
          ];
          var dd = new Date(row.date);
          row.date =
            dd.getDate() +
            " " +
            monthNames[dd.getMonth()] +
            ", " +
            dd.getFullYear();
          customerList.push(row);
        });

        localStorage.setItem("@CUSTOMERS_LIST", JSON.stringify(customerList));
      })
      .catch((er) => {
        console.log(er);
      });
  },
  // mounted() {
  //   this.customerList = JSON.parse(localStorage.getItem("@CUSTOMERS_LIST"));
  // },

  methods: {
    async getusers() {
      await Axios.get("/admin/tracking-customers")
        .then((data) => {
          this.customerList = [];
          data.data.data.forEach((row) => {
            const monthNames = [
              "Jan",
              "Fev",
              "Mar",
              "Avr",
              "Mai",
              "Juin",
              "Juil",
              "Aou",
              "Sept",
              "Oct",
              "Nov",
              "Dec",
            ];
            var dd = new Date(row.date);
            row.date =
              dd.getDate() +
              " " +
              monthNames[dd.getMonth()] +
              ", " +
              dd.getFullYear();
            this.customerList.push(row);
          });
        })
        .catch((er) => {
          console.log(er);
        });
    },
    async handleCreateUser() {
      if (this.fields.password.length < 6) {
        alert("Le mot de passe doit contenir au moins 6 caractères");
        return;
      }

      store.dispatch("setGlobalLoading", true);
      await Axios.post("/admin/tracking-customers", this.fields)
        .then(async () => {
          document.getElementById("closemodal").click();
          await this.getusers();
        })
        .catch((error) => {
          if (error.response?.data && error.response?.data?.message) {
            alert(error.response.data.message);
          } else {
            alert("Une erreur est survenue !");
          }
        })
        .finally(() => {
          store.dispatch("setGlobalLoading", false);
        });
    },
    editdata(data) {
      let result = this.customerList.findIndex(
        (o) => o.customerId == data.customerId
      );
      (document.getElementById("edtidfield1").value =
        this.customerList[result].customerId),
        (document.getElementById("edtrolefield").value =
          this.customerList[result].role);
    },
    updatedata() {
      let result = this.customerList.findIndex(
        (o) => o._id == document.getElementById("edtidfield1").value
      );
      this.customerList[result].role =
        document.getElementById("edtrolefield").value;
      if (this.customerList[result].role == "ADMIN") {
        this.customerList[result].roleClass = "warning";
      } else {
        this.customerList[result].roleClass = "success";
      }

      document.getElementById("edtclosemodal").click();

      store.dispatch("setGlobalLoading", true);
      Axios.put(
        `/admin/tracking-customers/update-role/${
          document.getElementById("edtidfield1").value
        }`,
        { role: this.customerList[result].role }
      )
        .then(() => {})
        .catch((er) => {
          console.log(er);
        })
        .finally(() => {
          store.dispatch("setGlobalLoading", false);
        });
    },
    // deletedata(event) {
    //   Swal.fire({
    //     title: "Are you sure?",
    //     text: "You won't be able to revert this!",
    //     icon: "warning",
    //     showCancelButton: true,
    //     cancelButtonColor: "#f46a6a",
    //     confirmButtonColor: "#34c38f",
    //     confirmButtonText: "Yes, delete it!",
    //   }).then((result) => {
    //     if (result.value) {
    //       this.customerList.splice(this.customerList.indexOf(event), 1);
    //       Axios.delete(
    //         `https://api-node.themesbrand.website/apps/customer/${event._id}`
    //       )
    //         .then(() => {})
    //         .catch((er) => {
    //           console.log(er);
    //         });
    //       Swal.fire("Deleted!", "Your file has been deleted.", "success");
    //     }
    //   });
    // },

    setPages() {
      let numberOfPages = Math.ceil(this.customerList.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(customerList) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return customerList.slice(from, to);
    },
    deleteMultiple() {
      let ids_array = [];
      var items = document.getElementsByName("chk_child");
      items.forEach(function (ele) {
        if (ele.checked == true) {
          var trNode = ele.parentNode.parentNode.parentNode;
          var id = trNode.querySelector(".id a").innerHTML;
          ids_array.push(id);
        }
      });
      if (typeof ids_array !== "undefined" && ids_array.length > 0) {
        if (confirm("Are you sure you want to delete this?")) {
          var cusList = this.customerList;
          ids_array.forEach(function (id) {
            console.log(id);
            cusList = cusList.filter(function (customer) {
              return customer.customerId != id;
            });
          });
          this.customerList = cusList;
          document.getElementById("checkAll").checked = false;
          var itemss = document.getElementsByName("chk_child");
          itemss.forEach(function (ele) {
            if (ele.checked == true) {
              ele.checked = false;
              ele.closest("tr").classList.remove("table-active");
            }
          });
        } else {
          return false;
        }
      } else {
        Swal.fire({
          title: "Please select at least one checkbox",
          confirmButtonClass: "btn btn-info",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
  },
  mounted() {
    this.customerList = JSON.parse(localStorage.getItem("@CUSTOMERS_LIST"));
    var checkAll = document.getElementById("checkAll");
    if (checkAll) {
      checkAll.onclick = function () {
        var checkboxes = document.querySelectorAll(
          '.form-check-all input[type="checkbox"]'
        );
        if (checkAll.checked == true) {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = true;
            checkbox.closest("tr").classList.add("table-active");
          });
        } else {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = false;
            checkbox.closest("tr").classList.remove("table-active");
          });
        }
      };
    }
  },
  components: {
    Layout,
    PageHeader,
    lottie: Lottie,
    // Multiselect,
    flatPickr,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="customerList">
          <div class="card-header border-bottom-dashed">
            <div class="row g-4 align-items-center">
              <div class="col-sm">
                <div>
                  <h5 class="card-title mb-0">Liste des clients</h5>
                </div>
              </div>
              <div class="col-sm-auto">
                <div>
                  <!-- <button
                    class="btn btn-soft-danger me-1"
                    @click="deleteMultiple"
                  >
                    <i class="ri-delete-bin-2-line"></i>
                  </button> -->
                  <button
                    type="button"
                    class="btn btn-success add-btn me-1"
                    data-bs-toggle="modal"
                    id="create-btn"
                    data-bs-target="#showModal"
                  >
                    <i class="ri-add-line align-bottom me-1"></i> Ajouter un
                    client
                  </button>
                  <!-- <button type="button" class="btn btn-info">
                    <i class="ri-file-download-line align-bottom me-1"></i>
                    Import
                  </button> -->
                </div>
              </div>
            </div>
          </div>
          <div class="card-body border-bottom-dashed border-bottom">
            <form>
              <div class="row g-3">
                <div class="col-xl-6">
                  <div class="search-box">
                    <input
                      type="text"
                      class="form-control search"
                      placeholder="Rechercher un client, un e-mail, un téléphone, ou autre..."
                      v-model="searchQuery"
                    />
                    <i class="ri-search-line search-icon"></i>
                  </div>
                </div>
                <!--end col-->
                <div class="col-xl-6">
                  <div class="row g-3">
                    <div class="col-sm-4">
                      <div class="">
                        <flat-pickr
                          v-model="date"
                          placeholder="Filtrer par date"
                          :config="rangeDateconfig"
                          class="form-control"
                        ></flat-pickr>
                      </div>
                    </div>
                    <!--end col-->

                    <div class="col-sm-4">
                      <div>
                        <button type="button" class="btn btn-primary w-100">
                          <i class="ri-equalizer-fill me-2 align-bottom"></i
                          >Filtre
                        </button>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                </div>
              </div>
              <!--end row-->
            </form>
          </div>
          <div class="card-body">
            <div>
              <div class="table-responsive table-card mb-1">
                <table class="table align-middle" id="customerTable">
                  <thead class="table-light text-muted">
                    <tr>
                      <th scope="col" style="width: 50px">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="checkAll"
                            value="option"
                          />
                        </div>
                      </th>

                      <th class="sort" data-sort="customer_name">Client</th>
                      <th class="sort" data-sort="email">E-mail</th>
                      <th class="sort" data-sort="phone">Telephone</th>
                      <th class="sort" data-sort="date">Date d'ajout</th>
                      <th class="sort" data-sort="role">Nombre de colis</th>
                      <th class="sort" data-sort="action">Actions</th>
                    </tr>
                  </thead>
                  <tbody class="list form-check-all">
                    <tr v-for="(item, index) of resultQuery" :key="index">
                      <th scope="row">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="chk_child"
                            value="option1"
                          />
                        </div>
                      </th>
                      <td class="id" style="display: none">
                        <a
                          href="javascript:void(0);"
                          class="fw-medium link-primary"
                          >{{ item.customerId }}</a
                        >
                      </td>
                      <td class="customer_name">{{ item.customer }}</td>
                      <td class="email">{{ item.email }}</td>
                      <td class="phone">{{ item.phone }}</td>
                      <td class="date">{{ item.date }}</td>
                      <td class="role">{{ item.requests_count }}</td>
                      <td>
                        <ul class="list-inline hstack gap-2 mb-0">
                          <!-- <li
                            class="list-inline-item edit"
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="Edit"
                          >
                            <a
                              href="#editmodal"
                              data-bs-toggle="modal"
                              class="text-primary d-inline-block edit-item-btn"
                              @click="editdata(item)"
                            >
                              <i class="ri-pencil-fill fs-16"></i>
                            </a>
                          </li> -->
                          <li
                            class="list-inline-item"
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="Afficher les colis"
                          >
                            <a
                              class="text-primary d-inline-block remove-item-btn"
                              @click="deletedata(item)"
                            >
                              <i class="ri-eye-fill fs-16"></i>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="noresult" style="display: none">
                  <div class="text-center">
                    <lottie
                      class="avatar-xl"
                      colors="primary:#121331,secondary:#08a88a"
                      :options="defaultOptions"
                      :height="75"
                      :width="75"
                    />
                    <h5 class="mt-2">Pardon! Aucun résultat trouvé</h5>
                    <p class="text-muted mb-0">
                      Nous n'avons trouvé aucun client pour votre recherche.
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end mt-3">
                <div class="pagination-wrap hstack gap-2">
                  <a
                    class="page-item pagination-prev disabled"
                    href="#"
                    v-if="page != 1"
                    @click="page--"
                  >
                    Precedent
                  </a>
                  <ul
                    class="pagination listjs-pagination mb-0"
                    v-if="resultQuery.length >= 8"
                  >
                    <li
                      :class="{
                        active: pageNumber == page,
                        disabled: pageNumber == '...',
                      }"
                      v-for="(pageNumber, index) in pages.slice(
                        page - 1,
                        page + 5
                      )"
                      :key="index"
                      @click="page = pageNumber"
                    >
                      <a class="page" href="#">{{ pageNumber }}</a>
                    </li>
                  </ul>
                  <a
                    class="page-item pagination-next"
                    href="#"
                    @click="page++"
                    v-if="page < pages.length"
                  >
                    Suivant
                  </a>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              id="showModal"
              tabindex="-1"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header bg-light p-3">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Ajout D'un client
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="close-modal"
                    ></button>
                  </div>
                  <form action="#" id="addform">
                    <div class="modal-body">
                      <input type="hidden" id="id-field" />

                      <div class="mb-3" id="modal-id" style="display: none">
                        <label for="id-field1" class="form-label">ID</label>
                        <input
                          type="text"
                          id="idfield1"
                          class="form-control"
                          placeholder="ID"
                          readonly
                        />
                      </div>

                      <div class="mb-3">
                        <label for="customername-field" class="form-label"
                          >Nom du client</label
                        >
                        <input
                          type="text"
                          id="customername"
                          class="form-control"
                          placeholder="Entrer le nom du client"
                          required
                          v-model="fields.client_name"
                        />
                      </div>

                      <div class="mb-3">
                        <label for="customername-field" class="form-label"
                          >Numéro de téléphone</label
                        >
                        <input
                          type="text"
                          id="customername"
                          class="form-control"
                          placeholder="Entrer le numéro de téléphone du client"
                          required
                          v-model="fields.phone"
                        />
                      </div>

                      <div class="mb-3">
                        <label for="email-field" class="form-label"
                          >Adresse E-mail</label
                        >
                        <input
                          type="email"
                          id="email"
                          class="form-control"
                          placeholder="Entrer l'e-mail"
                          required
                          v-model="fields.email"
                        />
                      </div>

                      <div class="mb-3">
                        <label for="location-field" class="form-label">
                          Localisation
                        </label>
                        <input
                          type="text"
                          id="location-field"
                          class="form-control"
                          placeholder="Entrer le mot de passe"
                          required
                          v-model="fields.password"
                        />
                      </div>

                      <!-- <div>
                        <label for="status-field" class="form-label"
                          >Role</label
                        >
                        <select
                          class="form-control"
                          data-trigger
                          id="statusfield"
                          v-model="fields.role"
                        >
                          <option value="CUSTOMER">Client</option>
                          <option value="ADMIN">Administrateur</option>
                        </select>
                      </div> -->
                    </div>
                    <div class="modal-footer">
                      <div class="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          id="closemodal"
                          class="btn btn-light"
                          data-bs-dismiss="modal"
                        >
                          Fermer
                        </button>
                        <button
                          type="button"
                          class="btn btn-success"
                          @click="handleCreateUser"
                        >
                          Enregistrer le client
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              id="editmodal"
              tabindex="-1"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header bg-light p-3">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Modifier les droits du client
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="close-modal"
                    ></button>
                  </div>
                  <form action="#" id="addform">
                    <div class="modal-body">
                      <input type="hidden" id="id-field" />

                      <div class="mb-3" id="modal-id" style="display: none">
                        <label for="id-field1" class="form-label">ID</label>
                        <input
                          type="text"
                          id="edtidfield1"
                          class="form-control"
                          placeholder="ID"
                          readonly
                        />
                      </div>

                      <div>
                        <label for="role-field" class="form-label">Role</label>
                        <select
                          class="form-control"
                          data-trigger
                          id="edtrolefield"
                        >
                          <option value="">Role</option>
                          <option value="ADMIN">ADMIN</option>
                          <option value="CUSTOMER">CUSTOMER</option>
                        </select>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <div class="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          id="edtclosemodal"
                          class="btn btn-light"
                          data-bs-dismiss="modal"
                        >
                          Fermer
                        </button>
                        <button
                          type="button"
                          class="btn btn-success"
                          @click="updatedata"
                        >
                          Modifier
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>
